import React, { useState } from "react";
import { Container, Row, Col, Pagination } from "react-bootstrap";
import { projects } from "../../data/data";
import ProjectCard from "./sub-components/ProjectCard";

const Projects = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 6;

  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;

  const currentProjects = projects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  const totalPages = Math.ceil(projects.length / projectsPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);

    const projectsSection = document.getElementById("projects");
    if (projectsSection) {
      projectsSection.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <div className="Project">
              <h2>Projects</h2>
              <p>
                Explore a diverse range of my recent projects, each showcasing
                my passion and skills in web development. From dynamic web
                applications to creative design solutions, these projects
                reflect my dedication to creating innovative and user-friendly
                digital experiences.
              </p>
            </div>
            <Row>
              {currentProjects.map((project, index) => {
                return <ProjectCard key={index} {...project} />;
              })}
            </Row>
            <Pagination className="justify-content-center mt-4">
              {Array.from({ length: totalPages }, (_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={index + 1 === currentPage}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Projects;
